import React from 'react';
import { Col, Row } from 'antd';

import { staticAssetsPrefix, appStoreUrl, playStoreUrl } from '../../lib/utils';
import { spacing, minScreenSize, maxScreenSize } from '../../lib/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AppCustomLink from '../Common/AppCustomLink';

export default function Footer() {
    return (
        <div className='footer-div'>
            <footer style={{ maxWidth: '1032px', margin: 'auto' }}>
                <Row>
                    <Col xs={24} md={12} xl={12} offset={0} style={{ marginBottom: '1.5em' }}>
                        {/* Logo */}
                        {/* <img className='logo-img-footer' src={`${staticAssetsPrefix}/static/images/Logo.svg`} alt='Accelerated logo' /> */}
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1em' }} >
                            {/* <img src={`${staticAssetsPrefix}/static/images/main-page/landing.svg`} /> */}
                            <img style={{ width: '200px' }} className='logo-img' src={`${staticAssetsPrefix}/static/images/logo-dark.png`} alt='Accelerated logo' />
                            {/* <h3 style={{ color: 'white', fontWeight: '700', marginBottom: '0' }}>&nbsp; Accelerated</h3> */}
                        </div>
                        <p className='dim-color serif'>Accelerated is the best book summary app for iOS, Android, Web, Windows and MacOS with no ads.</p>
                        <div>
                            <a style={{ marginRight: '1em' }} href={appStoreUrl} target='_blank' rel="noopener noreferrer">
                                <img style={{ width: '130px' }} src={`${staticAssetsPrefix}/static/images/app_store.svg`} />
                            </a>
                            <a href={playStoreUrl} target='_blank' rel="noopener noreferrer">
                                <img style={{ width: '160px' }} src={`${staticAssetsPrefix}/static/images/google-play-badge.png`} />
                            </a>
                        </div>
                    </Col>
                    <Col xs={24} md={{ span: 5, offset: 1 }} xl={{ span: 5, offset: 1 }} style={{ textAlign: 'left', marginBottom: '1.5em' }}>
                        {/* <a href={`${domainNameWeb}privacy-policy`}>Privacy Policy</a> */}
                        <div className='link-parent'>
                            <AppCustomLink href='/privacy-policy' ><a className='footer-link serif'>Privacy Policy</a></AppCustomLink>
                        </div>
                        <div className='link-parent'>
                            <AppCustomLink href='/terms-and-conditions' ><a className='footer-link serif'>Legal</a></AppCustomLink>
                        </div>
                        <div className='link-parent'>
                            <AppCustomLink href='/cookies-details' ><a className='footer-link serif'>Cookies</a></AppCustomLink>
                        </div>
                    </Col>
                    <Col xs={24} md={6} xl={5}>
                        <h4 className='dim-color' style={{ marginBottom: '0.75em' }}>Connect</h4>
                        <div style={{ marginBottom: '0.5em' }}>
                            <a href="mailto: support@tedapp.ai">
                                <FontAwesomeIcon className='social-icon' icon={['fas', 'envelope']} style={{ fontSize: '1.2em' }} />
                            </a>
                            <a href='https://twitter.com/tedapp' target='_blank' rel="noopener noreferrer">
                                <FontAwesomeIcon className='social-icon' icon={['fab', 'twitter']} style={{ fontSize: '1.2em' }} />
                            </a>
                            {/* <a href='https://www.reddit.com/r/Accelerated' target='_blank' rel="noopener noreferrer">
                                <FontAwesomeIcon className='social-icon' icon={['fab', 'reddit']} />
                            </a>
                            <a href='https://discord.gg/pezGHSd' target='_blank' rel="noopener noreferrer">
                                <FontAwesomeIcon className='social-icon' icon={['fab', 'discord']} style={{ fontSize: '1.1em' }} />
                            </a> */}
                        </div>
                        <p className='dim-color'>support@tedapp.ai</p>
                        <p className=''>London, United Kingdom</p>
                    </Col>
                </Row>
            </footer>
            <style jsx>
                {`
                    .footer-div {
                        // height: 8vh;
                        background-color: #182538; // black;
                        color: white;
                        padding: 4em; // ${spacing()};
                    }
                    :global(.social-icon) {
                        width: 1.5em;
                        font-size: 1.25em;
                        margin-right: 1.25em;
                        cursor: pointer;
                        color: white;
                    }
                    .logo-img-footer {
                        width: 20%;
                    }
                    .link-parent {
                        margin-bottom: 0.75em;
                    }
                    :global(.footer-link) {
                        color: #f2f2f2 !important;
                    }
                    .dim-color {
                        color: #f2f2f2;
                    }
                    @media ${maxScreenSize.tablet} {
                        .logo-img-footer {
                            width: 50%;
                        }
                        .footer-div {
                            padding: 2em 2em;
                        }
                    }
                    @media ${minScreenSize.tablet} and ${maxScreenSize.laptop} {
                        .logo-img-footer {
                            width: 50%;
                        }
                    }
                `}
            </style>
        </div>
    )
}
